import { combineReducers } from 'redux';
import loading from './modules/loading'; // import toast from "./modules/toast";
import ui from './modules/ui';
import user, { LOGOUT_SUCCESS } from './modules/user';
import navigation from './modules/navigation';
import page from './modules/page';
import campaign from './modules/campaign';
import modal from './modules/modal';
import common from './modules/common';
import wallet from './modules/wallet';

const appReducer = combineReducers({
  loading,
  // toast,
  // dialog,
  ui,
  user,
  navigation,
  page,
  campaign,
  modal,
  common,
  wallet,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    return appReducer({ ui: state.ui }, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
