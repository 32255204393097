import { SAVE_USER_SUCCESS } from '../modules/user';
import {
  CAMPAIGN_CRITERIA_SAVE_SUCCESS,
  CAMPAIGN_SAVE_SUCCESS,
} from '../modules/campaign';
import { getMark } from '../../util';

export default function loadingMiddleware(storeAPI) {
  return function wrapDispatch(next) {
    return function handleAction(action) {
      // Do anything here: pass the action onwards with next(action),
      // or restart the pipeline with storeAPI.dispatch(action)
      // Can also use storeAPI.getState() here

      // if(action.type === SAVE_USER_SUCCESS) {
      //
      //   const success = action.payload;
      //   if (success) {
      //     Object.keys(success).map((key) => {
      //       setTimeout(() => {
      //         storeAPI.dispatch(clearSuccess(key, 'user'));
      //       }, 5000);
      //     });
      //   }
      // }

      // if(action.type === CAMPAIGN_SAVE_SUCCESS || action.type === CAMPAIGN_CRITERIA_SAVE_SUCCESS) {
      //
      //   const success = action.payload;
      //   if (success) {
      //     Object.keys(success).map((key) => {
      //       setTimeout(() => {
      //         storeAPI.dispatch(clearSuccess(key, 'campaign'));
      //       }, 5000);
      //     });
      //   }
      // }

      // if(action.type && action.mark && Object.keys(action.mark).length > 0 && action.type.startsWith('user/') && action.type.endsWith('REQUEST')){
      //
      //   storeAPI.dispatch(clearSuccess(key, mod));
      //
      // }

      if (
        action.type &&
        action.type.indexOf('CLEAR_SUCCESS') === -1 &&
        action.type.endsWith('SUCCESS')
      ) {
        const mod = action.type.split('/')[0];

        const success = getMark(action);
        if (success) {
          try {
            // Object.keys(success).map((key) => {
            setTimeout(() => {
              const key = success;
              storeAPI.dispatch(clearSuccess(key, mod));
            }, 5000);
            // });
          } catch (e) {
            console.error('INVALID payload in successmiddlewre');
          }
        }
      }

      return next(action);
    };
  };
}

export function clearSuccess(key, mod) {
  return {
    type: mod + '/CLEAR_SUCCESS',
    payload: key,
  };
}
