import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import User from '../left-sidebar-1/user';
import { TextInput } from '../inputs/TextInput';
import actions from '../../services/redux/actions';
import Button from '../action/Button';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../display/Loader';

export default function AskPassword({ onChange, onSuccess }) {
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );

  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);

  const change = (val) => {
    setPass(val);
    if (onChange) {
      onChange({ password: val });
    }
  };

  const login = async () => {
    setLoading(true);
    await onSuccess();
    setLoading(false);
  };

  const enter = login;

  return (
    <div className="bg-brand-light rounded-lg text-white">
      <div className="text-center text-white font-medium pt-2 mb-2">
        Session Expired
      </div>
      <div>
        <User showUsername={false} showType={false} />
      </div>
      <div className="flex flex-row pb-8">
        <div className="flex-1"></div>
        <div className="flex-initial w-40 relative" style={{ height: '44px' }}>
          <div className={`${loading && 'invisible'}`}>
            <TextInput
              name="password"
              type="password"
              placeholder="Enter Password"
              onEnter={enter}
              onChange={change}
              showEye={false}
              value={pass}
            />
          </div>
          <div
            className={`absolute w-full h-full text-center m-auto self-center  ${
              !loading && 'invisible'
            }`}
            style={{ bottom: '-12px' }}
          >
            <div>
              <Loader type="light" />
            </div>
          </div>
        </div>
        <div className={`flex-1 self-center ${loading && 'invisible'}`}>
          <Button icon={faArrowRight} onClick={enter}></Button>
        </div>
      </div>
    </div>
  );
}
