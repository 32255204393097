import { createStore, applyMiddleware } from 'redux';
import rootReducer from './redux/reducers';
import { client, fileUpload, fileUploadProgress } from './api';
// import throttle from "redux-throttle";
import clientMiddleware from './redux/middleware/clientMiddleware';
// import loadingMiddleware from "./redux/middleware/loadingMiddleware";
// import dialogMiddleware from "./redux/middleware/dialogMiddleware";
import successMiddleware from './redux/middleware/successMiddleware';
// import authMiddleware from "./redux/middleware/authMiddleware";
// import jwtSaveMiddleware from "./redux/middleware/jwtSaveMiddleware";
// import sessionMiddleware from "./redux/middleware/sessionMiddleware";
// import toastMiddleware from "./redux/middleware/toastMiddleware";
import thunk from 'redux-thunk';
import { ls } from './util';

// const defaultWait = 1000
// const defaultThrottleOption = { // https://lodash.com/docs#throttle
//   leading: true,
//   trailing: false
// }

// const throttleMiddleware = throttle(defaultWait, defaultThrottleOption);
let reduxStore;

export const initializeStore = (initialState) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (typeof window === 'undefined') {
    return makeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!reduxStore) {
    reduxStore = makeStore(initialState);
  }

  return reduxStore;
};

const makeStore = (preloadedState = {}) => {
  const store = createStore(
    rootReducer,
    {},
    applyMiddleware(
      thunk,
      // throttleMiddleware,

      // dialogMiddleware,
      // loadingMiddleware,
      clientMiddleware(client, fileUpload, fileUploadProgress),
      successMiddleware
      // sessionMiddleware,
      // authMiddleware(client),
      // jwtSaveMiddleware,
      // toastMiddleware
    )
  );

  // store.subscribe(() => {
  // const user = store.getState().user;
  // if (user) {
  //   if (user.access) {
  //     ls.setItem('access', user.access);
  //   } else {
  //     ls.removeItem('access');
  //   }
  //   if (user.refresh) {
  //     ls.setItem('refresh', user.refresh);
  //   } else {
  //     ls.removeItem('refresh');
  //   }
  // }
  // });
  return store;
};
