// import {decodeUser} from "../../util";

import {
  ASK_PASSWORD_DIALOG,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  refresh,
  REFRESH_TOKEN_SUCCESS,
} from '../modules/user';
import { decodeJWT } from '../../mapping';
import actions from '../actions';
import AskPassword from '../../../components/modals/AskPassword';

export default function clientMiddleware(
  client,
  fileUpload,
  fileUploadProgress
) {
  return ({ dispatch, getState }) => {
    return (next) => async (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      const user = getState().user;
      if (!user || !user.access || !decodeJWT(user.access, true)) {
        if (user.refresh && decodeJWT(user.refresh, true)) {
          const tokens = await refresh()({ dispatch, getState })
            .promise(client, fileUpload, fileUploadProgress)
            .catch((e) => console.log); //.then(response => {return response.json()});
          await next({ type: REFRESH_TOKEN_SUCCESS, payload: tokens });
        } else {
          if (
            user &&
            user.user_id &&
            action.type !== LOGIN_REQUEST &&
            action.type !== LOGIN_SUCCESS &&
            action.type !== LOGIN_FAILURE &&
            action.service !== 'email-login'
          ) {
            await new Promise((resolve, reject) => {
              return dispatch(
                actions.modal.show({
                  title: 'Session Expired',
                  content: <AskPassword />,
                  successButtonText: 'Login',
                  width: 'w-1/3',
                  contentPadding: false,
                  showFooter: false,
                  // bgFade:false,
                  onSuccess: async (data) => {
                    const u = await dispatch(
                      actions.user.login('email', {
                        username: user.username,
                        password: data && data.password,
                      })
                    );
                    resolve(u);
                  },
                  showClose: false,
                  showHeader: false,
                })
              );
            });
          } else {
          }
        }
      }

      const actionPromise = promise(client, fileUpload, fileUploadProgress);

      actionPromise.then(
        (result) => {
          return next({ ...rest, result, type: SUCCESS });
        },
        (error) => {
          return next({ ...rest, error, type: FAILURE });
        }
      );
      // actionPromise.then(
      //   (result) => next({...rest, result, type: SUCCESS}),
      //   (error) => next({...rest, error, type: FAILURE})
      // );

      // actionPromise.catch((error)=> {
      //   return next({...rest, error, type: FAILURE});
      // });

      return actionPromise;
    };
  };
}
